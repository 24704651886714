import {createRouter, createWebHistory} from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'
const routes = [
    {
        path: '/',
        redirect: '/inicio',
        component: () => import('../components/layouts/DefaultLayout.vue'),
        meta: { requiresAuth: true},
        children: [
            {path: '/inicio', name: 'Inicio', component: () => import('../views/Inicio.vue')},

            {path: '/usuarios', name: 'Usuarios', component: () => import('../views/usuarios/usuarios/Lista.vue')},
            {path: '/roles-permisos', name: 'RolesPermisos', component: () => import('../views/usuarios/roles-permisos/Lista.vue')},
            {path: '/roles/:id/asignar-permisos', name: 'AsignarPermisos', component: () => import('../views/usuarios/roles-permisos/Permiso.vue')},
            {path: '/personales', name: 'Personales', component: () => import('../views/usuarios/personales/Lista.vue')},
            {path: '/personales/:id/detalle', name: 'DetallePersonal', component: () => import('../views/usuarios/personales/Detalle.vue')},

            {path: '/solicitudes', name: 'Solicitudes', component: () => import('../views/solicitudes/Lista.vue')},
            {path: '/solicitudes/crear', name: 'CrearSolicitud', component: () => import('../views/solicitudes/Formulario.vue')},
            {path: '/solicitudes/:id', name: 'EditarSolicitud', component: () => import('../views/solicitudes/Formulario.vue')},
            {path: '/solicitudes/:id/detalle', name: 'DetalleSolicitud', component: () => import('../views/solicitudes/Detalle.vue')},

            {path: '/entregas', name: 'Entregas', component: () => import('../views/entregas/Lista.vue')},
            {path: '/entregas/crear', name: 'CrearEntrega', component: () => import('../views/entregas/Formulario.vue')},
            {path: '/entregas/:id/detalle', name: 'DetalleEntrega', component: () => import('../views/entregas/Detalle.vue')},


            {path: '/devoluciones', name: 'Devoluciones', component: () => import('../views/devoluciones/Lista.vue')},
            {path: '/devoluciones/crear', name: 'CrearDevolucion', component: () => import('../views/devoluciones/Formulario.vue')},
            {path: '/devoluciones/:id/detalle', name: 'DetalleDevolucion', component: () => import('../views/devoluciones/Detalle.vue')},


            { path: '/articulos', name: 'Artículos', component: () => import('../views/gestion_articulo/articulos/Lista.vue')},
            { path: '/tipos_elementos', name: 'Tipos de elementos', component: () => import('../views/gestion_articulo/tipos_elementos/Lista.vue')},
            { path: '/clasificacion', name: 'Clasificación', component: () => import('../views/gestion_articulo/clasificacion/Lista.vue')},
            { path: '/grupo', name: 'Grupo', component: () => import('../views/gestion_articulo/grupo/Lista.vue')},
            { path: '/genero', name: 'Genero', component: () => import('../views/gestion_articulo/genero/Lista.vue')},
            { path: '/unidad_medida', name: 'Unidad medida', component: () => import('../views/gestion_articulo/unidad_medida/Lista.vue')},

            { path: '/personal', name: 'Personal', component: () => import('../views/gestion_personal/personal/Lista.vue')},
            { path: '/puestos', name: 'Puestos', component: () => import('../views/gestion_personal/puestos/Lista.vue')},
            { path: '/puestos/:id/asignar-articulos', name: 'Asignar articulos', component: () => import('../views/gestion_personal/puestos/AsignacionArticulo.vue') },
            { path: '/cargo', name: 'Cargo', component: () => import('../views/gestion_personal/cargo/Lista.vue')},
            { path: '/sucursal', name: 'Sucursal', component: () => import('../views/gestion_personal/sucursal/Lista.vue')},
            { path: '/division', name: 'Division', component: () => import('../views/gestion_personal/division/Lista.vue')},

            { path: '/codigo-cuenta', name: 'Codigo cuenta', component: () => import('../views/configuracion/codigo_cuenta/Lista.vue')},
            { path: '/tipo-solicitud', name: 'Tipo de solicitud', component: () => import('../views/configuracion/tipo_solicitud/Lista.vue')},
            { path: '/tipo-devolucion', name: 'Tipo de devolución', component: () => import('../views/configuracion/tipo_devolucion/Lista.vue')},

            { path: '/inventarios', name: 'Inventarios', component: () => import('../views/inventarios/Lista.vue')},
            {path: '/inventarios/:id/detalle', name: 'DetalleInventario', component: () => import('../views/inventarios/Detalle.vue')},
            


            {path: '/error/403', name: 'Forbidden', component: () => import('../views/errors/403.vue')},


        ]
    },
    {
        path: '/auth',
        redirect: '/login',
        name: ' Auth',
        component: () => import('../components/layouts/AuthLayout.vue'),
        meta: { isGuest: true },
        children: [
            {
                path: '/login',
                name: 'Login',
                component: () => import('../views/auth/Login.vue')
            }

        ],
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()
    if(to.meta.requiresAuth && !authStore.token){
        next({name: "Login"})
    }else{
        next();
    }
})

// router.beforeEach((to, from, nex) => {
//
// })

export default router
