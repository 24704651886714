import axios from 'axios'
import {useAuthStore} from '@/stores/AuthStore'
import router from '@/router'
import toast from '@/plugins/toast';
const axiosClient = axios.create({
    baseURL: `${import.meta.env.VITE_APP_API_URL}`,
})


axiosClient.interceptors.request.use(config => {
// Do something before request is sent
    const authStore = useAuthStore()
    config.headers.Authorization = `Bearer ${authStore.token}`
    return config;
}, error => {
// Do something with request error
    return Promise.reject(error);
});


axiosClient.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        sessionStorage.removeItem('TOKEN')
        router.push({path: '/login'})
    }
    if (error.response.status === 403) {
        const message = error.response.data.error
        toast.error(message)
        router.push({name: 'Forbidden'})
    }
    if (error.response.status === 404) {
        // router.push({name: 'NotFound'})
    }
    return Promise.reject(error);
})


export default axiosClient
