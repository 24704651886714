import {defineStore} from 'pinia'
import axiosClient from '@/plugins/axios'
import sessionStorageApp from '@/plugins/sessionStorage'
import toast from '@/plugins/toast';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        usuario: sessionStorageApp.obtenerUsuario() || null,
        token: sessionStorageApp.get('token') || null,
        expires_at: sessionStorageApp.get('expires_at') || null,
        loading: false,
        message: '',
        inactive: null,
        success: null,
        url: '/auth'
    }),

    getters: {},

    actions: {
        async login(data) {
            this.loading = true
            try {
                const response = await axiosClient.post(`${this.url}/login`, data)
            
                const {usuario, roles, permisos, token, expires_at, success, message } = response.data;

                sessionStorageApp.set("usuario", usuario);
                sessionStorageApp.set("roles", roles);
                sessionStorageApp.set("permisos", permisos);
                sessionStorageApp.set('token', token);
                sessionStorageApp.set("expires_at", expires_at);

                this.usuario = usuario;
                this.token = token;
                this.expires_at = expires_at;
                this.success = success;
                this.message = message
            } catch (error) {
                if (error.response) {
                    // Si hay una respuesta en el error, manejar el error relacionado con la respuesta de la solicitud
                    // console.log('Error de respuesta:', error.response.data);
                    // Puedes acceder a información específica de la respuesta, como el mensaje de error
                    this.message = error.response.data.message;
                } else if (error.request) {
                    // Si la solicitud se hizo pero no se recibió respuesta
                    this.message = `No se recibió respuesta, ${error.message}.`
                } else {
                    // Si ocurrió un error durante la configuración de la solicitud
                    console.log('Error al configurar la solicitud:', error.message);
                }
                // this.message = error.response.data.message
                this.success = false
            } finally {
                this.loading = false
            }
        },

        async logout( ){
            try {
                const response = await axiosClient.post('/auth/logout')
                // this.usuario = null;
                // this.token = null
                // this.expires_at = null
                
                sessionStorage.clear()
                this.success = true;
                toast.success("Sesión finalizada correctamente!!");
               
                // sessionStorage.removeItem("expires_at");
                // sessionStorage.removeItem("TOKEN");
                // sessionStorage.removeItem("usuario");
            } catch (error) {
                console.log(error)
            }
        },


    }
})
