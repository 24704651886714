const appName = import.meta.env.VITE_APP_NAME

const Storage = {
    setUser(usuario){
        this.set('usuario', usuario)
    },
    obtenerUsuario() {
        return this.get('usuario')
    },
    set(key, value){
        sessionStorage.setItem(`${appName}_${key}` , JSON.stringify(value))
    },

    get(key){
        const data = sessionStorage.getItem(`${appName}_${key}`)
        try {
            return JSON.parse(data)
        }  catch (error) {
            return data
        }
      }
}
export default Storage