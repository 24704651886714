const timeout = 5000;

export default {
    async success(message) {
        await showNotification(message, 'success');
    },
    async error(message) {
        await showNotification(message, 'danger');
    },
};

const showNotification = async (message, bgClass) => {
    const html = `<div class="custom-notification"> <div class="alert alert-${bgClass}" role="alert">${message}</div> </div>`;

    document.body.insertAdjacentHTML('beforeend', html);
    await wait(timeout);
    clearNotification();
};

const wait = (timeout) => {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
};

const clearNotification = () => {
    const element = document.body.querySelector('.custom-notification');
    if (element) {
        document.body.removeChild(element);
    }
};
