// import './assets/main.css'
import '@/assets/css/bootstrap.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap'
import '@/assets/css/style.css'

import '@/assets/css/responsive.css'
// import '@/assets/css/colors.css'
import '@/assets/css/perfect-scrollbar.css'
// import '@/assets/js/jquery.min'
// import '@/assets/js/popper.min'
// import '@/assets/js/bootstrap'
import { accessPermission } from "@/plugins/acl";

import router from '@/router'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'


import AppButton from '@/components/AppButton.vue';
import PageComponent from '@/components/layouts/PageComponent.vue';
import AppLoading from '@/components/AppLoading.vue'


const pinia = createPinia()
const app = createApp(App)
app.component('AppButton', AppButton)
app.component('PageComponent', PageComponent)
app.component('AppLoading', AppLoading)
app.use(pinia)
app.use(router)
app.directive("can", accessPermission);
app.mount('#app')
