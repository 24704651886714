const appName = import.meta.env.VITE_APP_NAME
import sessionStorageApp from '@/plugins/sessionStorage'


const accessPermission = {
  mounted: (el, binding) => {
    let permissions = sessionStorageApp.get('permisos');
  
    let requiredPermissions = binding.value;

    let logicType = binding.arg || '';
    
    if (!Array.isArray(requiredPermissions)) {
      requiredPermissions = [requiredPermissions];
    }
    // console.log(requiredPermissions.some(permission => permissions.includes(permission)))
   let hasPermission
    if(logicType === 'required') {
      hasPermission = !requiredPermissions.every(permission => permissions.includes(permission))
    } else {
      hasPermission = !requiredPermissions.some(permission => permissions.includes(permission))
    }
    // hasPermission = !requiredPermissions.some(permission => permissions.includes(permission))

    if (hasPermission) {
      el.parentNode.removeChild(el);
    }

    // Verificar si al menos un permiso de la lista requerida está presente
    // if (!requiredPermissions.some(permission => permissions.includes(permission))) {
    //   el.parentNode.removeChild(el);
    // }

  }
};

export { accessPermission };

// const accessPermission = {
//   mounted: (el, binding) => {
//     let permissions = sessionStorageApp.get('permisos')
   
//     let requiredPermissions = binding.value;
 
//     if (!Array.isArray(requiredPermissions)) {
//       requiredPermissions = [requiredPermissions];
//     }
//     console.log(!Array.isArray(requiredPermissions))
//     if (!requiredPermissions.every(permission => permissions.includes(permission))) {
//       el.parentNode.removeChild(el);
//     }
//     // if (!permissions.includes(binding.value)) {
//     //   el.parentNode.removeChild(el);
//     // }
//   }
// };

// export { accessPermission };
