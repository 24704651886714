<template>
  <button class="btn cur-p" :class="buttonClass" style="margin-left: 5px;">
      {{ title }}
  </button>

</template>

<script setup>
import { ref, computed } from 'vue';


const props = defineProps({
    title: {
        type: String,
        required: true
    },
    button: {
        type: String,
        required: true
    },
    icon: {
        type: Boolean,
        default: false,
    }
})

const buttonClass = computed(() => {
    if(props.button === 'crear' || props.button === 'editar')
    {
        return 'btn-success';
    }
    if(props.button === 'registrar')
    {
        return 'btn-primary'
    }
    if(props.button === 'cancelar')
    {
        return 'btn-secondary'
    }
    if(props.button === 'ver')
    {
        return 'btn-info';
    }
    if(props.button === 'eliminar')
    {
        return 'btn-danger';
    }
    if(props.button === 'detalle')
    {
        return 'btn-outline-secondary';
    }
})

</script>

